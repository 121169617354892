import  {Route, Switch, useLocation} from 'react-router-dom';
import AboutUsPage from './pages/AboutUsPage';
import ErrorPage from './pages/ErrorPage';
import HomePage from './pages/HomePage';
import Events from './pages/Events';
import Login from './pages/Login';
import VBS2024 from './pages/VBS/Main';
import One80Jam from './pages/One80Jam/One80Jam';
import SundaySchool from './pages/SundaySchool/SundaySchool';
import SSMain from './pages/SundaySchool/SSMain';
import Main from './pages/SongSheet/Main';
import DramaMinistry from './pages/Drama/DramaMinistry';
import MainNav from './layout/MainNav';
import Footer from './layout/Footer';
import BannerLive from './component/BannerLive';
import TransposerPage from './pages/Transposer/TransposerPage';
import Search from './component/Search';
import React, { useState, useEffect } from 'react';
import Media from './pages/Media';
import Brochure from './pages/Brochure';
import May2022 from './files/conference/May 2022.pdf';
import Nov2022 from './files/conference/November 2022.pdf';
import May2023 from './files/conference/MAY 2023.pdf';
import Dec2023 from './files/conference/December 2023.pdf';
import May2024 from './files/conference/May 2024.pdf';
import BDec2023 from './files/brochure/December 2023.png';
import BMay2024 from './files/brochure/regions-beyond.jpg';
import Nov2024 from './files/conference/112024.pdf';
import BNov2024 from './files/brochure/harvest2024.jpg';

import '@react-pdf-viewer/core/lib/styles/index.css';

import { getFilePlugin } from '@react-pdf-viewer/get-file';

function App(){
    const getFilePluginInstance = getFilePlugin();
    const { Download } = getFilePluginInstance;
    var conference = [
        { monthYear: '052022',  btn: 'Click here to Download SEASON OF REVIVAL - MAY 2022 Song Sheet',   file: May2022 },
        { monthYear: '112022',  btn: 'Click here to Download Advance - November 2022 Song Sheet',   file: Nov2022 },
        { monthYear: '052023',  btn: 'Click here to Download Fulfilling our Task - May 2023 Song Sheet',   file: May2023 },
        { monthYear: '122023',  btn: 'Click here to Download Mission Ready! - December 2023 Song Sheet',   file: Dec2023 },
        { monthYear: '052024',  btn: 'Click here to Regions Beyond! - May 2024 Song Sheet',   file: May2024 },
        { monthYear: '112024',  btn: 'Click here to Download - Nomveber 2024 Song Sheet',   file: Nov2024 },
    ];
    var brochures = [
        { monthYear: '122023',  file: BDec2023, label: 'December 2023'},
        { monthYear: '052024',  file: BMay2024, label: 'May 2024' },
        { monthYear: '112024',  file: BNov2024, label: 'November 2024' },
    ];
    return( 
        <div>
            <MainNav/>
            <Switch>
                <Route exact path="/chords" component={Search} />
                <Route exact path='/'><HomePage /></Route>
                <Route path='/about-us'><AboutUsPage /></Route>
                <Route path='/drama-ministry'><DramaMinistry /></Route>
                <Route path='/events'><Events /></Route>
                <Route path='/one80JAM'><One80Jam /></Route>
                <Route path='/request'><TransposerPage /></Route>
                <Route path='/login'><Login/></Route>
                <Route path='/ss'><SundaySchool /></Route>
                <Route path='/media'><Media /></Route>
                <Route path='/vbs2024'><VBS2024 /></Route>

                <Route exact path="/brochure/:monthYear" render={({match}) => (
                    <Brochure brochures={brochures.find(p => p.monthYear === match.params.monthYear)} />
                )} />

                <Route exact path="/conference/:monthYear" render={({match}) => (
                    <Main conference={conference.find(p => p.monthYear === match.params.monthYear)} />
                )} />
                
                <Route path="" component={ErrorPage} />
            </Switch>
            <BannerLive/>
            <Footer/>
        </div>
    );   
}

export default App;